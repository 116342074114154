<template>
  <div
    class="bg-ash-100 bg-opacity-50 flex items-center"
  >
    <div
      class="max-w-1500 mx-auto px-5 py-5"
    >
      <!-- right -->
      <div
        class="lg:pl-24 pl-0 lg:pt-4 pt-0"
      >
      <div class="pb-3">
        <DgImg
          src="logo/imgLogo"
          :style="{width: '200px'}"
        />
      </div>
        <ForgotCard />
      </div>
    </div>
  </div>
</template>

<script>
import ForgotCard from '@/views/forgot/ForgotCard.vue';
import DgImg from '../../components/base/DgImg.vue';

export default {
  name: 'Forgot',
  components: {
    ForgotCard,
    DgImg,
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
