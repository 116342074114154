<template>
  <div
    class="shadow-md rounded-md lg:px-20 px-5 lg:py-14 py-10 bg-white w-full"
    :style="{'max-width': '650px'}"
  >
    <div
      class="flex lg:flex-row flex-col lg:justify-between justify-center lg:items-end items-center"
    >
      <p
        class="text-xl text-ash-800 whitespace-nowrap"
      >
        忘記密碼?
      </p>
      <div>
      <div
        class="flex items-center"
      >

        <router-link
          :to="{
            name: 'Login'
          }"
          class="gtm-forgot-link flex items-center group"
        >
          <DgIcon
            size="7"
            name="chevron-left"
            color="ash-600"
            class="mr-3"
            hoverColor="primary"
          />
          <p
            class="text-base text-ash-600 whitespace-nowrap group-hover:text-primary"
          >返回登入</p>
        </router-link>
        <span
          class="text-ash-600 px-4"
        >|</span>
        <router-link
          :to="{
            name: 'Home'
          }"
          class="gtm-forgot-link"
        >
          <span
            class="text-ash-600 text-center hover:text-primary"
          >返回下載列表</span>
        </router-link>
      </div>
      </div>
    </div>
    <p
      class="text-base text-ash-600 my-10 text-center"
    >請輸入註冊時之電子信箱或手機號碼，我們將以發送連結(mail)或輸入驗證碼(手機)之方式進行密碼重設</p>
    <div
      class="grid grid-cols-2 gap-5"
    >
      <DgForm
        ref="form"
        class="col-span-2"
        formKey="init.forgot"
        :settings="settings"
      />
    </div>
    <button
      class="gtm-forgot-btn bg-primary-gradient-to-r py-2 text-lg text-white rounded-full w-full
      mt-14"
      @click="methodOnPress"
    >{{buttonText}}</button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import DgForm from '@/components/dgForm/Index.vue';
import DgIcon from '@/components/base/DgIcon.vue';
import DgInput from '@/components/dgForm/DgInput.vue';
import { checkMail, checkPhone } from '@/utils/validator';
import requestApi from '@/lib/http';

export default {
  name: 'ForgotCard',
  components: {
    DgForm,
    DgIcon,
  },
  data() {
    return {
      component: DgInput,
      loginThirdList: {
        facebook: '',
        line: '',
        google: '',
      },
      value: {
        email: '',
        phone: '',
        code: '',
      },
      showValidCode: false,
    };
  },
  computed: {
    buttonText() {
      return this.showValidCode ? '送出驗證碼' : '寄送新密碼';
    },
    settings() {
      return [
        {
          hide: this.showValidCode,
          componentType: 'text',
          col: '2',
          inputType: 'email',
          uniKey: 'email',
          placeholder: '請輸入您的 email',
          rules: [
            checkMail,
          ],
        },
        {
          hide: this.showValidCode,
          componentType: '',
          label: '或',
          uniKey: 'none',
          labelClass: 'text-base text-ash-600 text-center w-full',
        },
        {
          hide: this.showValidCode,
          componentType: 'text',
          col: '2',
          inputType: 'tel',
          uniKey: 'phone',
          placeholder: '請輸入您的手機',
          rules: [
            checkPhone,
          ],
        },
        // {
        //   hide: !this.showValidCode,
        //   componentType: 'text',
        //   col: '2',
        //   uniKey: 'code',
        //   placeholder: '請輸入驗證碼',
        //   rules: [
        //   ],
        // },
      ];
    },
  },
  methods: {
    ...mapMutations({
      showNotifySuccess: 'setNotifySuccess',
      showNotifyError: 'setNotifyError',
      showLoading: 'setIsLoading',
    }),
    async methodForgot() {
      const params = this.$refs.form.methodGetContent();
      if (!params) {
        this.showNotifyError('請檢查錯誤提示項目');
        return;
      }
      const {
        email = '',
        phone = '',
      } = params;

      if (!email && !phone) {
        this.showNotifyError('電子信箱或手機號碼，請擇一輸入');
        return;
      }
      const resultParams = {};
      if (email) {
        resultParams.email = email;
      } else {
        resultParams.phone = phone;
      }

      this.showLoading(true);
      const { status, message/* , result */ } = await requestApi('login.forget', resultParams);

      if (status) {
        if (email) {
          this.showNotifySuccess('已寄送重設密碼連結至您的信箱');
        } else {
          this.showNotifySuccess('已寄送重設驗證碼至您的手機');
        }
        if (email) {
          this.$router.push({
            name: 'Home',
          });
        } else {
          this.showValidCode = true;
          setTimeout(() => {
            this.showValidCode = false;
          }, 10 * 60 * 1000);
        }
      } else {
        this.showNotifyError(`重設失敗，${message}`);
      }
      this.showLoading(false);
    },
    async methodPhoneVerify() {
      const params = this.$refs.form.methodGetContent();
      if (!params || !params?.phone) {
        this.showNotifyError('請檢查錯誤提示項目');
        return;
      }
      const {
        code = '',
        phone = '',
      } = params;

      const resultParams = {
        code,
        phone,
      };

      this.showLoading(true);
      const { status, message, result } = await requestApi('login.phoneVerify', resultParams);

      if (status) {
        this.showValidCode = false;
        this.$router.push({
          name: 'ResetPassword',
          query: {
            token: result?.data?.token,
          },
        });
      } else {
        this.showNotifyError(`重設失敗，${message}`);
      }
      this.showLoading(false);
    },
    methodOnPress() {
      if (this.showValidCode) {
        this.methodPhoneVerify();
      } else {
        this.methodForgot();
      }
    },
  },
};
</script>
